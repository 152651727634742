import { ERROR_LEVELS, Logger, LoggerService } from '../../services/logger';
import {
  Invitation,
  Inviter,
  InviterInviteOptions,
  Session,
  SessionDescriptionHandler,
  SessionInviteOptions,
  SessionState,
  UserAgent,
  Web
} from 'sip.js';
import { IncomingRequestMessage, IncomingResponse, IncomingResponseMessage, OutgoingInviteRequest, URI } from 'sip.js/lib/core';
import {
  AcceptInvitationOptions,
  CompositeDisplayName,
  DisplayName,
  EndCallOptions,
  InviteOptions,
  SessionWrapperDelegates,
  SimpleSessionCallOptions
} from '../models';

export class SessionWrapper {

  private logger: Logger;

  public session: Session;
  private _muted: boolean = false;
  private _held: boolean = false;
  private _holdInProgress: boolean = false;
  private _mediaAttached: boolean = false;
  private _incoming: boolean; // Is the session incoming?
  private _mediaStream: MediaStream = new MediaStream();
  private _startTime: number = 0;
  private _displayName: DisplayName;
  private _isCanceled: boolean = false;
  private _isLoseRace: boolean = false;
  private _isRejected: boolean = false;
  private _rejectStatus: number;

  // Using delegates to be able to pass the session on which these events are fired
  // The stateChangeListener is not enough to understand which session was firing the event
  private _delegates: SessionWrapperDelegates

  // This only keep the session. As the session is generated by the User Agent we can't create it in here.
  constructor(session: Session, loggerService: LoggerService) {

    // This class is not an angular service / component
    this.logger = loggerService.getLoggerInstance('SessionWrapper');

    this.session = session;
    this._incoming = session instanceof Invitation ? true : false;
    if (this._incoming) {
      // If it's an invitation, extends the onCancel method to allow us to
      // understand if the call was cancelled because it was answered elsewhere
      const oldOnCancel = (<Invitation>this.session)._onCancel;
      (<Invitation>this.session)._onCancel = (message: IncomingRequestMessage) => {
        if (message.getHeader('Reason').includes('LOSE_RACE')) {
          this._isCanceled = true;
          this._isLoseRace = true;
        }
        oldOnCancel.call(this.session, message);
      }
    }
    this._displayName = this.generateDisplayName();
    this.setStateChangeListener(this.stateListener);
  }

  /**
   * Set a listener for the session stateChange event
   * @param {(data: SessionState) => void} listener Listener to state change. It receive a SessionState argument
   */
  public setStateChangeListener(listener: (data: SessionState) => void) {
    this.session.stateChange.addListener(listener);
  }

  /**
   * Send an invite sip message
   * @param {InviteOptions} inviteOptions Invite options
   */
  public invite(inviteOptions?: InviteOptions) {
    // Seems like setting constraints here doesn't work as of now. Maybe in 0.17 it's fixed
    let _inviteOptions: InviteOptions = inviteOptions || {};
    // const inviterInviteOptions: InviterInviteOptions = {
    //   sessionDescriptionHandlerOptions: {
    //     constraints: _inviteOptions.constraints || { audio: true, video: false }
    //   }
    // }
    // console.log(inviterInviteOptions)
    const inviterOptions: InviterInviteOptions = {
      requestDelegate: {
        onAccept: (res) => {
          console.debug(res)
        },
        onTrying: (res) => {
          this.logger.debug(res.message);
          if(_inviteOptions.delegates?.onTryingDelegate)
            _inviteOptions.delegates.onTryingDelegate(res);
        },
        onProgress: (res) => {
          this.logger.debug(res.message);
          if(_inviteOptions.delegates?.onProgressDelegate)
            _inviteOptions.delegates.onProgressDelegate(res);
        },
        onReject: (res: IncomingResponse) => {
          this._isRejected = true;
          this._rejectStatus = res.message.statusCode;
        }
      }
    }
    this.session.invite(inviterOptions).then((request: OutgoingInviteRequest) => {
      this.logger.debug('Successfully sent INVITE');
      this.logger.debug('Invite request = ' + request);
    }).catch((error: Error) => {
      this.logger.error('Failed to send INVITE');
      throw error;
    });
  }

  /**
   * Get the session id of the session
   * @returns {string} The id of the session
   */
  public get sessionId(): string {
    return this.session.id;
  }

  /**
   * Get the current state of the session
   * @returns { SessionState } The state of the session
   */
  public get sessionState(): SessionState {
    return this.session.state;
  }

  /**
   * End the call
   */
  public endCall(options?: EndCallOptions) {
    switch (this.session.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        // I had to do 2 if because typescript wasn't taking well the else in terms of typings
        // Both cancel and reject are method of the subclass of Session so with instanceof the typings is correct
        if (this.session instanceof Inviter) {
          // Outgoing
          this.session.cancel();
        } else {
          // Incoming
          (<Invitation>this.session).reject(
            {
              statusCode: options?.rejectCode
            }
          )
        }
        break;
      case SessionState.Established:
        this.session.bye();
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
        this.logger.warn('Cannot terminate an already terminated session');
        break;
    }
  }

  /**
   * Returns the session description handler of the session for direct interaction with it.
   * @returns {SessionDescriptionHandler} Session description handler of the session
   */
  public get sessionDescriptionHandler(): SessionDescriptionHandler {
    return this.session.sessionDescriptionHandler;
  }

  /**
   * Send a Dtmf tone.
   * @param {string} tone The tone to send via Dtmf
   */
  public sendDtmf(tone: string): boolean {
    return this.sessionDescriptionHandler.sendDtmf(tone);
  }

  /**
   * Method used to attach the remote audio to an html element
   * @param {HTMLAudioElement} audioElement Html audio element to attach the remote stream to
   * @param {Boolean} autoplay Flag to start playing audio.
   */
  public attachRemoteAudio(audioElement: HTMLAudioElement, autoplay: boolean = true) {
    audioElement.srcObject = this._mediaStream;
    if (autoplay) audioElement.play();
  }

  /**
   * Attach the tracks to the stream of this session stream.
   * @private
   */
  private attachTracksToStream() {
    const sessionDescriptionHandler = this.sessionDescriptionHandler;
    // This will make sure we have a session description handler containing the peer connection (typescript things)
    if (!(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler");
    }
    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }
    peerConnection.getReceivers().forEach((receiver) => {
      if (receiver.track) {
        this._mediaStream.addTrack(receiver.track);
      }
    });
  }

  /**
   * Change the input device with the one passed
   * @param {string} deviceId The device id to use
   */
  public changeInputDevice(deviceId: string) {
    const sessionDescriptionHandler = this.sessionDescriptionHandler;
    // This will make sure we have a session description handler containing the peer connection (typescript things)
    if (!(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler");
    }
    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }
    peerConnection.getSenders().forEach((sender) => {
      if (sender.track) {
        sender.track.applyConstraints({
          deviceId: deviceId
        })
      }
    });
  }

  /**
   * Change the output device with the one passed
   * @param {string} deviceId The device id to use
   */
  public changeOutputDevice(deviceId: string) {
    const sessionDescriptionHandler = this.sessionDescriptionHandler;
    // This will make sure we have a session description handler containing the peer connection (typescript things)
    if (!(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler");
    }
    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }
    peerConnection.getReceivers().forEach((receiver) => {
      if (receiver.track) {
        receiver.track.applyConstraints({
          deviceId: deviceId
        })
      }
    });
  }

  /**
   * Mute the outgoing audio of the session
   */
  public mute() {
    if (this.muted) return;
    this.setMute(true);
  }

  /**
   * Unmute the outgoing audio of the session
   */
  public unmute() {
    if (!this.muted) return;
    this.setMute(false);
  }

  /**
   * Implementation for setting or unsetting the mute
   * @private
   * @param {boolean} mute Flag to set or unset the mute 
   */
  private setMute(mute: boolean) {
    let sdh = this.sessionDescriptionHandler;
    // This will make sure we have a session description handler containing the peer connection (typescript things)
    if (!(sdh instanceof Web.SessionDescriptionHandler)) {
      throw new Error("Session's session description handler not instance of SessionDescriptionHandler");
    }
    const peerConnection = sdh.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }
    // Get all sender of the peer connection and disable their track
    peerConnection.getSenders().forEach(sender => {
      if (sender.track) {
        sender.track.enabled = !mute;
      }
    });
    this._muted = mute;
  }

  /**
   * @returns {boolean} True if muted, false otherwise
   */
  public get muted() {
    return this._muted;
  }

  /**
   * Hold the session
   */
  public hold() {
    if (this.held) return;
    this.setHold(true);
  }

  /**
   * Unhold the session
   */
  public unhold() {
    if (!this.held) return;
    this.setHold(false);
  }

  /**
   * Logic to send the reinvite with the hold modifier set or unset
   * @private
   * @param {boolean} hold Set or un set hold
   */
  private setHold(hold: boolean) {
    if (this._holdInProgress || this.session.state === SessionState.Terminated) return;
    this._holdInProgress = true;
    const options: SessionInviteOptions = {
      requestDelegate: {
        onAccept: () => {
          this.logger.debug('Re-invite for hold accepted');
          // Used both to be sure to stop the audio and also for setting the mute flag
          // with the held flag
          hold ? this.mute() : this.unmute();
          this._held = hold;
          this._holdInProgress = false;
        },
        onReject: () => {
          this.logger.debug('Re-invite for hold rejected');
          this._holdInProgress = false;
        }
      },
      sessionDescriptionHandlerModifiers: hold ? [this.holdModifier] : []
    }
    // Send the invite
    this.session.invite(options).then(request => {
      this.logger.verbose('Re-invite sent successfully', request.message);
    }).catch(error => {
      this.logger.error('Error sendind re-invite', error);
    });
  }

  /**
   * @returns {boolean} True if the session is on hold, false otherwise
   */
  public get held() {
    return this._held;
  }

  public transfer(target: string | SessionWrapper, domain?: string) {
    let URI: URI = null;
    if (typeof target === 'string') {
      if (!target.startsWith('sip:')) {
        if (!domain) {
          throw new Error('If not using a sip uri or a session, the domain should be passed');
        }
        URI = UserAgent.makeURI(`sip:${target}@${domain}`);
      } else {
        URI = UserAgent.makeURI(target);
      }
      if (!URI) {
        throw new Error('Error creating the URI');
      }
    }
    // Because the URI is setted only if the type of the target is a string
    // we can assume that if it's not setted then it is a session wrapper
    this.session.refer(URI || (<SessionWrapper>target).session);
  }

  /**
   * Quick method to do a call
   * @param {SimpleSessionCallOptions} options Optionally pass the stateChangeLinstener and the inviteOptions
   */
  public call(options?: SimpleSessionCallOptions) {
    this.setStateChangeListener(options?.stateChangeListener || (() => { }));
    this.invite(options?.inviteOptions);
    this._startTime = Date.now();
  }

  /**
   * Accept the current invite
   * @param {AcceptInvitationOptions} options Optionally pass the option for the accept
   */
  public accept(options?: AcceptInvitationOptions) {
    if (!(this.session instanceof Invitation)) {
      this.logger.warn('This session in not an invitation');
      return;
    }
    options = options || {};
    this.session.accept({
      sessionDescriptionHandlerOptions: {
        constraints: options.constraints
      }
    });
    this._startTime = Date.now();
  }

  /**
   * Set the flag for media attached
   * @param {boolean} value Boolean if the media is attached or not
   */
  public set mediaAttached(value: boolean) {
    this._mediaAttached = value;
  }

  /**
   * Get the media attached flag
   * @returns {boolean} Return true if the media is attached, false otherwise
   */
  public get mediaAttached(): boolean {
    return this._mediaAttached;
  }

  /**
   * Get the display name
   * @returns {DisplayName} The display name of the remote party
   */
  public get remoteDisplayName(): DisplayName {
    return this._displayName;
  }

  /**
   * Set a new value for the remote of the display name (for example when a result if found from the CRMs)
   * @param {string} remote New value for the remote value of the display name
   */
  public setRemoteDisplayName(remote: string) {
    // Make no sense to change phone number of the session so the only thing we change is the name
    this._displayName.name.remote = remote;
  }

  /**
   * Get if the call was cancelled
   */
  public get isCanceled() {
    return this._isCanceled;
  }

  /**
   * Get if the call was answered elsewhere
   */
  public get isLoseRace() {
    return this._isLoseRace;
  }

  /**
   * Get if the call was rejected
   */
  public get isRejected(): boolean {
    return this._isRejected;
  }

  /**
   * Get the status code of the rejection
   */
  public get rejectCode(): number {
    return this._rejectStatus;
  }

  /**
   * Generate the display name structure
   * @returns { DisplayName } The display name generated
   */
  private generateDisplayName(): DisplayName {
    let displayName: DisplayName = {
      name: {
        remote: ''
      },
      number: ''
    };
    const remoteIdentity = this.session.remoteIdentity;
    if (this._incoming) {
      if (remoteIdentity.displayName.trim().startsWith('verso')) {
        // ex: verso Acme Real da Acme Fake (or phone number)
        // daSplit = ['verso Acme Real ', ' Acme Fake']
        let daSplit = remoteIdentity.displayName.split('da');
        // versoSplit = ['', ' AcmeReal ']
        let versoSplit = daSplit[0].split('verso');
        displayName.name.local = versoSplit[1].trim();
        displayName.name.remote = daSplit[1].trim();
        // If uri user (which contain the phone number of the caller) contains the splitted from
        // then use the uri.user
        // The reason is that when the displayname is in "verso *to* da *from*" format, if from is a number it's misssing the + on the international prefix
        // making the number difficult to read
        displayName.name.remote = remoteIdentity.uri.user.includes(displayName.name.remote) ?
          remoteIdentity.uri.user :
          displayName.name.remote;
      } else if(remoteIdentity.displayName.trim().startsWith('to')) {
        // ex: verso Acme Real da Acme Fake (or phone number)
        // daSplit = ['verso Acme Real ', ' Acme Fake']
        let daSplit = remoteIdentity.displayName.split('from');
        // versoSplit = ['', ' AcmeReal ']
        let versoSplit = daSplit[0].split('to');
        displayName.name.local = versoSplit[1].trim();
        displayName.name.remote = daSplit[1].trim();
        // If uri user (which contain the phone number of the caller) contains the splitted from
        // then use the uri.user
        // The reason is that when the displayname is in "verso *to* da *from*" format, if from is a number it's misssing the + on the international prefix
        // making the number difficult to read
        displayName.name.remote = remoteIdentity.uri.user.includes(displayName.name.remote) ?
          remoteIdentity.uri.user :
          displayName.name.remote;
      } else {
        displayName.name.remote = remoteIdentity.displayName || remoteIdentity.uri.user;
      }
    } else {
      displayName.name.remote = remoteIdentity.uri.user;
    }
    displayName.number = remoteIdentity.uri.user;
    return displayName;
  }

  /**
   * Get the call duration in milliseconds
   * @returns {number} The call duration in milliseconds
   */
  public get callDuration(): number {
    return this._startTime === 0 ? 0 : Date.now() - this._startTime;
  }

  /**
   * Set the delegates to execute when the state of the session changes
   * @param {SessionWrapperDelegates} v Delegates to attach
   */
  public set delegates(v: SessionWrapperDelegates) {
    this._delegates = v;
    // Because the incoming session already start in initial, the state change will never trigger
    // so we have to check here if the session is incoming, is in initial and the trigger the delegate.
    if (this.sessionState === SessionState.Initial && this.incoming && v.onInitial) {
      v.onInitial(this);
    }
  }

  /**
   * @returns {boolean} Returns true if the session is an incoming one. False otherwise
   */
  public get incoming(): boolean {
    return this._incoming;
  }

  /**
   * Method to call to clear the object (like the logger)
   */
  public destroy(): void {
    this.logger.close();
  }

  /**
   * State listener for the session
   * @param state New state of the session
   */
  private stateListener = (state: SessionState) => {
    switch (state) {
      case SessionState.Initial:
        if (this._delegates?.onInitial)
          this._delegates.onInitial(this);
        break;
      case SessionState.Establishing:
        if (this._delegates?.onEnstablishing)
          this._delegates.onEnstablishing(this);
        break;
      case SessionState.Established:
        this.attachTracksToStream();
        if (this._delegates?.onEnstablished)
          this._delegates.onEnstablished(this);
        break;
      case SessionState.Terminating:
        if (this._delegates?.onTerminating)
          this._delegates.onTerminating(this);
        break;
      case SessionState.Terminated:
        if (this._delegates?.onTerminated)
          this._delegates.onTerminated(this);
        break;
    }
  }

  /**
  * The modifier that should be used when the session would like to place the call on hold.
  * @param description - The description that will be modified.
  */
  private holdModifier(description: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
    if (!description.sdp || !description.type) {
      throw new Error("Invalid SDP");
    }
    let sdp = description.sdp;
    const type = description.type;
    if (sdp) {
      if (!/a=(sendrecv|sendonly|recvonly|inactive)/.test(sdp)) {
        sdp = sdp.replace(/(m=[^\r]*\r\n)/g, "$1a=sendonly\r\n");
      } else {
        sdp = sdp.replace(/a=sendrecv\r\n/g, "a=sendonly\r\n");
        sdp = sdp.replace(/a=recvonly\r\n/g, "a=inactive\r\n");
      }
    }
    return Promise.resolve({ sdp, type });
  }
}