export const LOCAL_STORAGE_TOKEN_KEY = '_vtks';

export interface loginResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  "as:client_id": string;
  userName: string;
  jwt: string;
  ".issued": string;
  ".expires": string;
}

export interface JwtStructure {
  nbf: number;
  exp: number;
  username: string;
  roles: string[];
}

// Used any in some field because i don't need that data, so i don't care about
// its model
export interface SubscriptionResponse {
  id: string;
  company_id: string;
  chargebee_customer_id: string;
  chargebee_subscription_id: string;
  billing_info: any;
  plan: any;
  addons: any[];
  status: any;
  creation_date: Date | string;
  trial_end_date: Date | string;
  activation_date: Date | string;
  next_billing_date: Date | string;
  due_invoices_count: number;
  monthly_total_cost: number;
  promotional_credit: number;
  refundable_credit: number;
  meta_data: Metadata;
  applied_coupons: any[];
}

export interface Metadata {
  plan_upgrade: { version: string };
  shop: any;
  max_dids: number;
  available_payment_methods: string[];
  pbx: {
    device_types: {
      desktop_app: number;
      csp: number;
      desk_phone: number;
      sip: number;
    };
  };
  pricing_info: { commitment_days: number; default_user_count: number };
  included_addons: IncludedAddons;
  max_users: number;
  users: { min: number; max: number };
  traffic: {
    initial_credit: number;
    outbound: { mobile: number; countries: string[]; landline: number }[];
  };
  subscription: { 'update-flow': string };
}

export interface IncludedAddons {
  call_queue?: any;
  call_recording?: any;
  headphones_rental?: any;
  phone_rental?: any;
  channel?: any;
  number_portability?: any;
  credit_topup?: any;
  fax?: any;
  public_api?: any;
  voices_professional?: any;
  did?: any;
  crm_integrations?: any;
  phone_number?: any;
  video_conference?: any;
  wifi?: any;
}